import React from "react"
import get from "lodash/get"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { ThemeProvider } from "../components/darkThemeContext"
import Helmet from "react-helmet"

const NotFoundPage = ({ data }) => {
  const images = get(data, "contentfulFour04.images", undefined)
  const rawRichText = get(data, "contentfulFour04.text.raw", undefined)

  //parse the contents of the rich-text data
  const richText = JSON.parse(rawRichText)
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <GatsbySeo title={`Ricky Richards Photography - Page Not found`} />
      <ThemeProvider>
        <Layout>
          <section className="px-8 md:px-14">
            <div className="flex flex-wrap pt-24 ">
              <div className="max-w-sm pr-12 whitespace-pre-wrap md:max-w-xs episode-rte">
                {documentToReactComponents(richText)}
              </div>
              {images.map(image => {
                console.log(image)
                return (
                  <div key={image.id} className="w-full md:max-w-md">
                    <GatsbyImage
                      image={image.gatsbyImageData}
                      alt={
                        image.description === ""
                          ? image.title
                          : image.description
                      }
                      className=" mb-14"
                    />
                  </div>
                )
              })}
            </div>
          </section>
        </Layout>
      </ThemeProvider>
    </>
  )
}

export const data = graphql`
  query {
    contentfulFour04 {
      text {
        raw
      }
      images {
        description
        id
        title
        gatsbyImageData(quality: 80)
      }
    }
  }
`

export default NotFoundPage
